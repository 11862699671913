import styled, { keyframes } from "styled-components";

export const ComingSoon = styled.div`
  color: ${(props) => props.theme.colors.accent};
  font-size: 1rem;
  font-weight: bold;
  margin-left: 20vw;
  width: 80vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.main`
  margin-left: 20%;
  width: 80%;
  overflow-y: auto;
  max-height: 100vh;
  padding: 0 1rem;

  @media (max-width: 768px) {
    margin-left: 4%;
    width: 96%;
    margin-top: 1rem;
    padding-bottom:6rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.3rem;
  font-weight: 700;
  color: ${(props) => props.theme.colors.text};
  margin-top: 0.5rem;
`;

export const TitleDescription = styled.p<{ fontSize?: string }>`
  font-size: ${(props) => props.fontSize ?? "0.85rem"};
  color: #707070;
`;

export const TitleWrapper = styled.section`
  margin: 2rem auto;
`;

export const CardsWrapper = styled.section`
  display: flex;
  gap: 1.5rem;
  margin: 2.5rem 3rem 1rem 1rem;

  @media (max-width: 768px) {
    margin: 2.5rem 1rem 1rem 0;
  }
`;

export const Card = styled.div<{ width: number; center?: string }>`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width}vw;
  height: 50vh;
  overflow: scroll;
  padding: 1rem;
`;

export const ExchangeCard = styled.div<{ width: number; center?: string }>`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width}vw;
  height: 50vh;
  padding: 1rem;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

export const CardTitle = styled.h2`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text};
  font-weight: 700;
`;

export const EmptyContainer = styled.div<{ center?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30vh;
  margin-top: ${(props) => props.center && "3rem"};
`;

export const EmptyTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
  color: ${(props) => props.theme.colors.textSecondary};
`;

export const EmptyTitleDescription = styled.p`
  color: ${(props) => props.theme.colors.accent};
  font-size: 0.875rem;
  margin: 0;
`;

export const CurrencySelectWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
`;

export const IconWrapper = styled.div`
  margin: 2rem 0.9rem 0;
`;

export const RateContainer = styled.div`
  background: ${(props) => props.theme.colors.transition};
  border-radius: 0.375rem;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 1rem;
  margin-top: 2rem;
`;

export const RateLabel = styled.h3`
  color: ${(props) => props.theme.colors.text};
  font-size: 0.85rem;
  font-weight: 500;
`;

export const RateContent = styled.p`
  font-size: 1.75rem;
  color: ${(props) => props.theme.colors.primary};
`;

export const TransferCardContainer = styled.div`
  border-radius: 8px;
  padding: 1rem;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const LaunchPadCardContainer = styled.div`
  border-radius: 8px;
  padding: 1rem;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const TransferIcon = styled.div`
  font-size: 2rem;
  margin-bottom: 10px;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const TranferTitle = styled.h2`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const TransferDescription = styled.p`
  font-size: 0.8rem;
  color: #707070;
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const TransferContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 75vw;
  animation: ${slideIn} 0.5s ease-out forwards;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90vw;
  }
`;

export const LaunchPadContainer = styled.div`
  display: flex;
  gap: 1rem;
  animation: ${slideIn} 0.5s ease-out forwards;
`;

export const TableWrapper = styled.div<{ height?: string }>`
  border-radius: 8px;
  width: 95%;
  margin: 1rem auto;
  height: ${(props) => props.height ?? "50vh"};
  overflow: scroll;
  border: 1px solid #f2f2f2;
`;

export const TableHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    align-items: start;
  }
`;

export const TableContainer = styled.div<{ noMargin?: string }>`
  border: 1px solid #f2f2f2;
  margin: ${(props) => (props.noMargin ? "1rem 0" : "2rem 0 1rem")};
  width: 95%;
  border-radius: 8px;
  padding-top: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 5rem;
  }
`;

export const DashboardCardContainer = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  padding: 0.8rem;
  cursor: pointer;
  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
  }
`;

export const DashboardCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DashboardCardMain = styled.p`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
`;

export const DashboardCardDescription = styled.label`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.accent};
  margin: 0;
`;

export const WalletContainer = styled.div<{ isActive?: boolean }>`
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  border: ${(props) => (props.isActive ? "1px solid #B52C2C" : " 1px solid #f2f2f2")};
  cursor: pointer;
`;
export const ModalContainer = styled.div`
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

export const InnerModalContainer = styled.div`
  padding: 1rem;
`;

export const WalletGrid = styled.div<{ business?: string }>`
  width: 60vw;
  display: grid;
  grid-template-columns: ${(props) => (props.business ? "1fr 1fr 1fr 1fr" : "1fr 1fr 1fr 1fr 1fr")};
  gap: 0.2rem;
  animation: ${slideIn} 0.5s ease-out forwards;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90vw;
    margin-bottom: 5rem;
  }
`;

export const TypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.8rem;
`;

export const WalletName = styled.p`
  font-weight: 500;
  font-size: 1.2rem;
`;

export const WalletNumber = styled.p`
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.accent};
`;

export const WalletType = styled.p`
  font-size: 0.8rem;
`;

export const AddWalletContainer = styled.div`
  width: 15vw;
  background-color: #f6f5f5;
  border-radius: 10px;
  padding: 1rem;
  border: 1px dashed #dfdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AddWalletIconContainer = styled.div`
  border-radius: 50%;
  padding: 0.2rem;
  background-color: #dfdcdc;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 516px;
  outline: none;
  font-weight: bold;
  font-size: 12px;
`;

export const UploadInput = styled.div`
  border: 1px solid #d9dfea;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(213, 213, 213, 0.5);
  padding: 0.4rem;
`;

export const FileTitle = styled.h4`
  font-size: 13px;
  line-height: 18px;
  color: #646466;
`;

export const UpdateUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const CheckIconContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const BusinessCard = styled.div`
  border: 1px solid #f2f2f2;
`;
