import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { currencies } from "src/helpers/countriesWithCurrencies";
import { WalletContainer, TitleDescription } from "../Styles";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { FormProvider, useForm } from "react-hook-form";
import { FormItem } from "./ui/form";
import useReactForm from "../../hooks/useForm";
import validate from "src/helpers/validate";
import {
  useCreatePaymentMethodsMutationApi,
  useCreateTradeRequestMutationApi,
  useGetPaymentOptions,
  useGetUserPaymentMethods,
} from "src/hooks/useTrading";
import { useUserData } from "src/hooks/useUserData";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getSelectedBusiness, updateUser } from "src/services";
import { useToast } from "./ui/use-toast";
import ReactSelect from "react-select";
import LoadingIndicator from "./LoadingIndicator";
import { useCreateBusinessTradeRequestMutationApi } from "src/hooks/useB2bTrading";

const tradeTypeOptions = [
  {
    label: "Buy",
    value: "BUY",
  },
  { label: "Sell", value: "SELL" },
];

const paymentIntervalOption = [
  {
    label: "Minutes",
    value: "MINUTES",
  },
  { label: "Hours", value: "HOURS" },
  { label: "Days", value: "DAYS" },
];

type AddTradeRequestProps = {
  open: boolean;
  handleClose: () => void;
  countries?: any;
  countriesIsLoading: boolean;
  isEdit?: boolean;
  tradeRequest?: any;
  businessId?: any;
  businessName?: string;
};

const AddTradeRequest = ({
  open,
  handleClose,
  countries,
  countriesIsLoading,
  tradeRequest,
  businessId,
  businessName,
}: AddTradeRequestProps) => {
  const { values, setValues, handleChange } = useReactForm(validate);

  const [tradeName, setTradeName] = useState("");
  const [paymentOption, setPaymentOption] = useState<any>({});
  const [accountDetails, setAccountDetails] = useState({
    name: "",
    account_number: "",
    provider_name: "",
  });

  const [selectedCurrency, setSelectedCurrencey] = useState<any>([]);
  const [selectedPayment, setSelectedPayment] = useState<any>([]);
  const [selectedPaymentInterval, setSelectedPaymentInterval] = useState<any>(null);

  const isEditState = Boolean(tradeRequest);
  const isBusiness = Boolean(businessId);

  useEffect(() => {
    const tradeCurrencies = currencies.filter((currency) =>
      tradeRequest?.currency_codes?.includes(currency.value),
    );
    if (isEditState) {
      setValues({
        tradeType: tradeRequest?.trade_type,
        amount: tradeRequest?.amount,
        min_amount: tradeRequest?.min_amount_usd,
        maxAmount: tradeRequest?.max_amount_usd,
        conversionRate: tradeRequest?.conversion_rate_usd,
        note: tradeRequest?.note,
      });
      setSelectedCurrencey(tradeCurrencies);
      setSelectedPayment(tradeRequest?.payment_methods);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditState]);

  const requestCurrencies = selectedCurrency.map((filter: any) => filter.value);
  const requestPayment = selectedPayment.map((filter: any) => filter.id);

  const handleTypeSelect = (event: any) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      tradeType: event,
    }));
  };

  const handlePaymentOptionSelect = (event: any) => {
    setPaymentOption(event);
  };

  const { data: selectedBusinessData, refetch: refetchSelectedBusinsessData } = useQuery({
    queryKey: ["selectedBusiness"],
    queryFn: () => getSelectedBusiness(businessId),
    enabled: !!businessId,
  });

  const { data: paymentMethodsResponse } = useGetUserPaymentMethods();
  const { data: paymentOptionsResponse } = useGetPaymentOptions();
  const { userData } = useUserData();
  const { createPaymentMethod } = useCreatePaymentMethodsMutationApi();
  const { createRequest, editRequest } = useCreateTradeRequestMutationApi();
  const { createBusinessRequest, editBusinessRequest } = useCreateBusinessTradeRequestMutationApi();

  const createRequestPayload = {
    trade_type: values.tradeType,
    amount: values.amount,
    min_amount_usd: values.min_amount,
    max_amount_usd: values.maxAmount,
    conversion_rate_usd: values.conversionRate,
    currency_codes: requestCurrencies,
    region: userData?.country_code,
    payment_methods: requestPayment,
    notes: values.notes,
  };

  function handleUserCreateTradeRequest() {
    const payload = {
      ...createRequestPayload,
      trading_name: userData?.trading_name,
      payment_time_limit_in_min: values.paymentTimeLine,
    };
    isEditState
      ? editRequest.mutate({ requestId: tradeRequest?.id, payload: payload })
      : createRequest.mutate(payload);
  }

  function handleCreateBusinessRequest() {
    const payload = {
      ...createRequestPayload,
      business_id: businessId,
      business_name: businessName,
      payment_time_limit_interval: selectedPaymentInterval,
      payment_time_limit_time: values.paymentTimeLine,
    };

    isEditState
      ? editBusinessRequest.mutate({ requestId: tradeRequest?.id, payload: payload })
      : createBusinessRequest.mutate(payload);
  }

  function handleCreateRequest() {
    if (isBusiness) {
      handleCreateBusinessRequest();
    } else {
      handleUserCreateTradeRequest();
    }
  }

  const { toast } = useToast();

  const paymentMethods = paymentMethodsResponse?.results || [];
  const paymentOptions = paymentOptionsResponse || [];

  const createTradingProfile =
    !userData?.trading_name || paymentMethods?.length < 1 || paymentOptions?.length < 1;

  const createTradeName = useMutation({
    mutationFn: (payload: any) => updateUser(userData?.id, payload),
    onSuccess: () => {
      toast({
        variant: "default",
        description: "Your details were updated successfully.",
      });
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: "There was a problem with your request.",
      });
    },
  });

  function handleCreateTradeName() {
    createTradeName.mutate({ trading_name: tradeName });
  }

  function handleCreatePaymentMethods() {
    createPaymentMethod.mutate({
      user_id: userData?.id,
      payment_option: paymentOption,
      account_details: accountDetails,
      note: "",
    });
  }

  const isLoading =
    createPaymentMethod.isPending || createTradeName.isPending || createRequest.isPending;

  //Disables submit button
  const disableCreateProfile =
    !tradeName ||
    paymentOption.length < 1 ||
    accountDetails.account_number === "" ||
    accountDetails.name === "" ||
    accountDetails.provider_name === "";

  const disableCreateRequest =
    !values.tradeType ||
    !values.amount ||
    !values.min_amount ||
    !values.maxAmount ||
    !values.conversionRate ||
    // !values.paymentTimeLine ||
    requestCurrencies?.length < 1 ||
    requestPayment?.length < 1;

  function handleCreateTradeProfile() {
    handleCreateTradeName();
    handleCreatePaymentMethods();
  }

  useEffect(() => {
    if (
      createRequest.isSuccess ||
      editRequest.isSuccess ||
      createBusinessRequest.isSuccess ||
      editBusinessRequest.isSuccess
    ) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createRequest.isSuccess,
    editRequest.isSuccess,
    createBusinessRequest.isSuccess,
    editBusinessRequest.isSuccess,
  ]);

  const methods = useForm();

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay style={{ inset: 1000 }} className="DialogOverlay">
          <Dialog.Content className="DialogContent">
            {isLoading ? (
              <div className="flex items-center justify-center min-h-80">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                <Dialog.Title style={{ marginBottom: 24 }} className="DialogTitle">
                  {createTradingProfile ? "Add trade profile details" : "Create Buy/Sell request"}
                </Dialog.Title>
                <Dialog.Close asChild>
                  <button className="IconButton" aria-label="Close" onClick={handleClose}>
                    <Cross2Icon />
                  </button>
                </Dialog.Close>
                <WalletContainer>
                  {!createTradingProfile && (
                    <FormProvider {...methods}>
                      <FormItem>
                        <TitleDescription>Trading name</TitleDescription>
                        <Input
                          type="text"
                          id="name"
                          readOnly
                          defaultValue={isBusiness ? businessName : userData?.trading_name}
                        />
                      </FormItem>
                      <FormItem style={{ marginTop: 15 }}>
                        <TitleDescription>Amount(AKL)</TitleDescription>
                        <Input
                          type="text"
                          name="amount"
                          placeholder="0.00"
                          onChange={handleChange}
                          defaultValue={values.amount}
                        />
                      </FormItem>
                      <FormItem style={{ marginTop: 15 }}>
                        <TitleDescription>Trade type</TitleDescription>
                        <Select
                          name="tradeType"
                          value={values.tradeType}
                          onValueChange={(event) => handleTypeSelect(event)}
                        >
                          <SelectTrigger className="w-[100%]">
                            <SelectValue placeholder="Buy/Sell" />
                          </SelectTrigger>
                          <SelectContent>
                            {tradeTypeOptions?.map(({ value, label }) => (
                              <SelectItem key={value} value={value}>
                                {label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormItem>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: 15,
                        }}
                      >
                        <FormItem>
                          <TitleDescription>Conversion rate(USD)</TitleDescription>
                          <Input
                            type="number"
                            name="conversionRate"
                            placeholder="USD"
                            onChange={handleChange}
                            defaultValue={values.conversionRate}
                          />
                        </FormItem>
                        <FormItem>
                          <TitleDescription>Min amount(USD)</TitleDescription>
                          <Input
                            type="number"
                            name="min_amount"
                            placeholder="0.00"
                            onChange={handleChange}
                            defaultValue={values.min_amount}
                          />
                        </FormItem>
                        <FormItem>
                          <TitleDescription>Max amount(USD)</TitleDescription>
                          <Input
                            type="number"
                            name="maxAmount"
                            placeholder="0.00"
                            onChange={handleChange}
                            defaultValue={values.maxAmount}
                          />
                        </FormItem>
                      </div>
                      {isBusiness && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: 15,
                            gap: 10,
                          }}
                        >
                          <div>
                            <TitleDescription>Payment interval</TitleDescription>
                            <Select
                              onValueChange={(event) => setSelectedPaymentInterval(event)}
                              defaultValue={selectedPaymentInterval}
                            >
                              <SelectTrigger className="w-[200px]">
                                <SelectValue placeholder="Min, Hrs, days" />
                              </SelectTrigger>
                              <SelectContent>
                                {paymentIntervalOption.map(
                                  ({ label, value }: { label: string; value: string }) => (
                                    <SelectItem key={value} value={value}>
                                      {label}
                                    </SelectItem>
                                  ),
                                )}
                              </SelectContent>
                            </Select>
                          </div>
                          <FormItem>
                            <TitleDescription>Payment time limit</TitleDescription>
                            <Input
                              type="number"
                              name="paymentTimeLine"
                              placeholder="00"
                              onChange={handleChange}
                              defaultValue={values.paymentTimeLine}
                              style={{ marginTop: 0 }}
                            />
                          </FormItem>
                        </div>
                      )}
                      {!isBusiness && (
                        <FormItem style={{ marginTop: 15 }}>
                          <TitleDescription>Payment time limit(Mins)</TitleDescription>
                          <Input
                            type="number"
                            name="paymentTimeLine"
                            placeholder="00"
                            onChange={handleChange}
                            defaultValue={values.paymentTimeLine}
                          />
                        </FormItem>
                      )}
                      <FormItem style={{ marginTop: 15 }}>
                        <TitleDescription>Currencies</TitleDescription>
                        <ReactSelect
                          options={currencies}
                          formatOptionLabel={(option) => `${option.value} - ${option.label}`}
                          isClearable
                          isSearchable
                          isMulti
                          onChange={(option) => {
                            setSelectedCurrencey([...option]);
                          }}
                        />
                      </FormItem>
                      <FormItem className="mt-3">
                        <TitleDescription>Add payment methods</TitleDescription>
                        <ReactSelect
                          options={paymentMethods}
                          onChange={(option: any) => setSelectedPayment([...option])}
                          value={selectedPayment}
                          getOptionLabel={({ payment_option, account_details }) =>
                            payment_option?.name + " - " + account_details?.provider_name
                          }
                          getOptionValue={({ id }) => id}
                          isMulti
                          isClearable
                        />
                      </FormItem>
                      <FormItem style={{ marginTop: 15 }}>
                        <TitleDescription>Note</TitleDescription>
                        <Input
                          type="text"
                          name="notes"
                          placeholder="Note"
                          onChange={handleChange}
                          defaultValue={values.notes}
                        />
                      </FormItem>
                    </FormProvider>
                  )}
                  {createTradingProfile && (
                    <div>
                      <FormItem>
                        <TitleDescription>Trade name</TitleDescription>
                        <Input
                          type="text"
                          id="tradeName"
                          placeholder="eg. johnDoe"
                          onChange={(event) => {
                            setTradeName(event.target.value);
                          }}
                          defaultValue={tradeName}
                        />
                      </FormItem>
                      <FormItem className="mt-3">
                        <TitleDescription>Select payment option</TitleDescription>
                        <Select
                          disabled={countriesIsLoading}
                          onValueChange={(event) => handlePaymentOptionSelect(event)}
                        >
                          <SelectTrigger
                            className="w-full"
                            onChange={(event: any) => handlePaymentOptionSelect(event)}
                          >
                            <SelectValue placeholder="select option" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {paymentOptions?.map(({ name, id }: { name: any; id: string }) => (
                                <SelectItem key={id} value={id}>
                                  {name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormItem>

                      <TitleDescription style={{ marginTop: 15 }}>Account details</TitleDescription>
                      <WalletContainer>
                        <FormItem>
                          <TitleDescription>Payment Provider name</TitleDescription>
                          <Input
                            type="text"
                            id="providerName"
                            placeholder="Bank name, Mobile Money Provider name "
                            onChange={(event) => {
                              setAccountDetails((prev: any) => ({
                                ...prev,
                                provider_name: event.target.value,
                              }));
                            }}
                            defaultValue={accountDetails.provider_name}
                          />
                        </FormItem>

                        <FormItem style={{ marginTop: 15 }}>
                          <TitleDescription>Account number</TitleDescription>
                          <Input
                            type="text"
                            id="accountNumber"
                            placeholder="eg. 00000000000"
                            onChange={(event) => {
                              setAccountDetails((prev: any) => ({
                                ...prev,
                                account_number: event.target.value,
                              }));
                            }}
                            defaultValue={accountDetails.account_number}
                          />
                        </FormItem>
                        <FormItem style={{ marginTop: 15 }}>
                          <TitleDescription>Account name</TitleDescription>
                          <Input
                            type="text"
                            id="accountName"
                            placeholder="John Doe"
                            onChange={(event) => {
                              setAccountDetails((prev: any) => ({
                                ...prev,
                                name: event.target.value,
                              }));
                            }}
                            defaultValue={accountDetails.name}
                          />
                        </FormItem>
                        <FormItem style={{ marginTop: 15 }}>
                          <TitleDescription>Note</TitleDescription>
                          <textarea
                            id="paymentNote"
                            name="paymentNote"
                            rows={5}
                            cols={50}
                            placeholder="Add note"
                            className={
                              "appearance-none flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            }
                          >
                            {/* default comment */}
                          </textarea>
                        </FormItem>
                      </WalletContainer>
                    </div>
                  )}
                </WalletContainer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    marginTop: 20,
                  }}
                >
                  <Button
                    onClick={createTradingProfile ? handleCreateTradeProfile : handleCreateRequest}
                    disabled={createTradingProfile ? disableCreateProfile : disableCreateRequest}
                    className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
                  >
                    {createTradingProfile || isEditState ? "Save" : "Submit request"}
                  </Button>
                </div>
              </>
            )}
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default AddTradeRequest;
