import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import * as Dialog from "@radix-ui/react-dialog";

import styled from "styled-components";
import { Button } from "./ui/button";
import { Cross2Icon } from "@radix-ui/react-icons";
import logo from "../../assests/images/logo-dark-h.png";

const Invoice = ({
  transactions,
  startDate,
  endDate,
  userData,
}: {
  transactions: any[];
  startDate: string;
  endDate: string;
  userData: any;
}) => {
  //Get the opening/initial balance at the time before a transaction was madewithin the time frame the user chose to print
  const opening_balance = 500;

  const totalDebit = transactions.reduce(
    (acc, transaction) =>
      transaction.debit_or_credit === "Debit" ? acc + Number(transaction.amount) : acc,
    0,
  );

  const totalCredit = transactions.reduce(
    (acc, transaction) =>
      transaction.debit_or_credit === "Credit" ? acc + Number(transaction.amount) : acc,
    0,
  );

  const totalCharges = transactions.reduce(
    (acc, transaction) => acc + (Number(transaction.charges) || 0),
    0,
  );

  return (
    <div
      style={{
        color: "black",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        margin: "20px 30px 30px 20px",
        minHeight: "100vh",
      }}
    >
      <img alt="logo" src={logo} height={80} width={400} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontSize: "12px",
          marginTop: "25px",
        }}
      >
        <p style={{ margin: "0px" }}>
          <span style={{ fontWeight: "bold" }}>Address:</span>Plot # 3 Behind Home Supermarket off
          Dawhenya Devtraco road Community 25 estate junction GPS Address: GN-0544-8908
        </p>
        <p style={{ margin: "0px" }}>
          <span style={{ fontWeight: "bold" }}>Phone:</span>+233 24 022 9282
        </p>
        <p style={{ margin: "0px" }}>
          <span style={{ fontWeight: "bold" }}>Email:</span>myhanypaystatement@hanypay.co
        </p>
        <p style={{ margin: "0px" }}>
          <span style={{ fontWeight: "bold" }}>Website:</span>{" "}
          <a href="https://hanypay.co">https://hanypay.co</a>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            marginTop: "25px",
          }}
        >
          Customer Hanypay Financial Statement
        </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "12px",
          }}
        >
          <p style={{ fontWeight: "bold" }}>Customer Information:</p>
          <p style={{ margin: "0px" }}>
            <span style={{ fontWeight: "bold" }}>Name:</span> {userData?.firstname}{" "}
            {userData?.othernames} {userData?.lastname}
          </p>
          <p style={{ margin: "0px" }}>
            <span style={{ fontWeight: "bold" }}>Account Number:</span>{" "}
            {userData?.wallets[0]?.number}
          </p>
          <p style={{ margin: "0px" }}>
            <span style={{ fontWeight: "bold" }}>Address:</span>
            {userData?.address}, {userData?.city}, {userData?.country}
          </p>
          <p style={{ marginTop: "0px" }}>
            <span style={{ fontWeight: "bold" }}>Statement Period:</span>
            {startDate} to {endDate}
          </p>
        </div>
      </div>
      <div style={{ marginTop: "25px", flexGrow: 1, pageBreakAfter: "auto" }}>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Description</th>
              <th>Debit(AKL)</th>
              <th>Charges</th>
              <th>Credit(AKL)</th>
              <th>Balance(AKL)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* Get the date for the opening/initial balance at the time before a transaction was madewithin the
              time frame the user chose to print */}
              <td>{new Date().toLocaleString("sv-SE")}</td>
              <td colSpan={4}>Opening Balance</td>
              <td>{opening_balance.toFixed(2)}</td>
            </tr>
            {transactions
              .sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
              .map((transaction, index) => {
                const balance = index === 0 ? opening_balance : transactions[index - 1].balance;

                const updatedBalance =
                  transaction.debit_or_credit === "Debit"
                    ? balance - Number(transaction?.charges) - Number(transaction.amount)
                    : balance + Number(transaction.amount);

                transaction.balance = updatedBalance;

                return (
                  <tr key={transaction.id}>
                    <td>{new Date(transaction.created_at).toLocaleString("sv-SE")}</td>
                    <td>{transaction.description || transaction?.channel}</td>
                    <td>
                      {transaction.debit_or_credit === "Debit" &&
                        Number(transaction.amount).toFixed(2)}
                    </td>
                    <td>
                      {transaction.debit_or_credit === "Debit" &&
                        Number(transaction.charges).toFixed(2)}
                    </td>
                    <td>
                      {transaction.debit_or_credit === "Credit" &&
                        Number(transaction.amount).toFixed(2)}
                    </td>
                    <td>{updatedBalance.toFixed(2)}</td>
                  </tr>
                );
              })}
          </tbody>
          <tfoot style={{ fontWeight: "bold" }}>
            <tr>
              <td colSpan={2}>Total</td>

              <td>{totalDebit.toFixed(2)}</td>
              <td>{totalCharges.toFixed(2)}</td>
              <td>{totalCredit.toFixed(2)}</td>
              <td>
                {transactions.length > 0
                  ? transactions[transactions.length - 1].balance.toFixed(2)
                  : "0.00"}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderLeft: "0px",
          borderTop: "0px",
          borderRight: "0px",
          borderBottom: "1px solid grey",
          marginTop: "25px",
        }}
      >
        <label>Notes:</label>
        <ol style={{ listStyleType: "disc" }}>
          <li>
            <span style={{ fontWeight: "bold" }}>Opening Balance:</span> The balance at the
            beginning of the statement period.
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}> Closing Balance:</span> The balance at the end of
            the statement period.
          </li>
          <li>Debit indicates money deducted from the account.</li>
          <li>Transactions are listed in chronological order.</li>
          <li>Credit indicates money added to the account.</li>
        </ol>
      </div>
      <p style={{ marginTop: "25px", fontWeight: "bold" }}>
        Thank you for choosing Hanypay Ghana Limited!
      </p>
      <div style={{ marginTop: "25px", display: "flex", flexDirection: "column" }}>
        <p>For any questions or concerns regarding this statement, please contact us at:</p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p>Phone: +233 24 022 9282</p>
          <p style={{ marginTop: "0px" }}>Email: myhanypaystatement@hanypay.co</p>
        </div>
      </div>
      <footer style={{ marginTop: "auto", position: "relative" }}>
        <p style={{ fontWeight: "bold" }}>
          Hanypay Ghana Ltd is licensed by the Africa Diaspora Central Bank (ADCB) with license
          number: LEI 894500ZVOL3A9L28LP28.
        </p>
      </footer>
    </div>
  );
};

const TransferStatement = ({
  open,
  handleClose,
  transactions,
  userData,
}: {
  open: boolean;
  handleClose: () => void;
  transactions: any[];
  userData: any;
}) => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handlePrint = () => {
    // Filter transactions based on the selected dates
    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.created_at); // Assuming each transaction has a 'date' property
      return transactionDate >= new Date(startDate) && transactionDate <= new Date(endDate);
    });

    // Print logic for filtered transactions
    console.log("filteredTransactions", filteredTransactions, transactions);
    const newWindow = window.open("", "_blank");
    newWindow?.document.write(`
      <html>
        <head>
          <title>Invoice</title>
          <style>
            body { font-family: Arial, sans-serif; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ddd; padding: 8px; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
         ${ReactDOMServer.renderToStaticMarkup(
           <Invoice
             startDate={startDate}
             endDate={endDate}
             transactions={filteredTransactions}
             userData={userData}
           />,
         )}
      </body>
      </html>
    `);
    newWindow?.document.close();
    newWindow?.print();
  };

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <DialogContent className="DialogContentSmall">
          <Dialog.Title className="DialogTitle">Transfer Statement</Dialog.Title>

          <Dialog.Description className="DialogDescription">
            Select dates to print out your bank statement
          </Dialog.Description>

          <div className="flex w-full flex-col space-y-4">
            <div className="flex w-full flex-col space-y-2">
              <label>From</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  const selectedStartDate = e.target.value;
                  if (endDate && new Date(selectedStartDate) > new Date(endDate)) {
                    setEndDate("");
                  }
                  setStartDate(selectedStartDate);
                }}
                className="border border-slate-200 p-2 outline-red-500"
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="flex w-full flex-col space-y-2">
              <label>To</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  const selectedEndDate = e.target.value;
                  if (startDate && new Date(selectedEndDate) < new Date(startDate)) {
                    setStartDate("");
                  }
                  setEndDate(selectedEndDate);
                }}
                className="border border-slate-200 p-2 outline-red-500"
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
          </div>

          <div style={{ display: "flex", marginTop: 25, justifyContent: "flex-end" }}>
            <Dialog.Close asChild>
              <StyledButton
                onClick={handlePrint}
                type="button"
                className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
              >
                Print
              </StyledButton>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <CloseIconButton onClick={handleClose} className="IconButton" aria-label="Close">
              <Cross2Icon width="20" height="20" />
            </CloseIconButton>
          </Dialog.Close>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

const CloseIconButton = styled.button`
  @media print {
    display: none;
  }
`;

const StyledButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const DialogContent = styled(Dialog.Content)`
  @media print {
    width: 100vw;
    heigt: 100vh;
  }
`;

export default TransferStatement;
