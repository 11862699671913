import React, { useEffect, useMemo, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import { Button } from "./ui/button";
import AddTradeOrders from "./AddTradeOrder";
import AddTradeRequest from "./AddTradeRequest";
import { useGetPaymentOptions, useTradingRequests } from "src/hooks/useTrading";
import { currencies } from "src/helpers/countriesWithCurrencies";
import { useQuery } from "@tanstack/react-query";
import { getCountries } from "src/services";
import { useUserData } from "src/hooks/useUserData";
import { TableContainer, TableHeading, TableWrapper } from "../Styles";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "./ui/table";
import { useB2bLiveTrades } from "src/hooks/useB2bTrading";

const BUY = "BUY";
const SELL = "SELL";

const LiveTrades = ({ businessId, businessName }: { businessId?: any; businessName?: string }) => {
  const [activeType, setActiveType] = useState(SELL);
  const [focusedRequest, setFocusedRequest] = useState<any>({});
  const [showCreateRequest, setShowCreateRequest] = useState(false);
  const [paymentFilter, setPaymentFilter] = useState<any>([]);
  const [countryFilter, setCountryFilter] = useState<any>([]);
  const [currencyFilter, setCurrencyFilter] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [page, setPage] = useState<number>(1);

  const { userData } = useUserData();

  const isBuyRequests = activeType === SELL;
  const isRequestInFocus = Object.values(focusedRequest).length > 0;
  const isBusinessTrade = Boolean(businessId);

  const { data: paymentOptionsResponse } = useGetPaymentOptions();
  const paymentOptions = paymentOptionsResponse || [];

  const { data: countries, isLoading: countriesIsLoading } = useQuery({
    queryKey: ["countries"],
    queryFn: () => getCountries(),
  });

  const {
    data: tradingRequestsResponse,
    isFetching,
    isError,
    isLoading,
    refetch,
  } = useTradingRequests({
    tradeType: activeType,
    currencyCode: currencyFilter?.value,
    // currencyCode: userData?.currency_code,
    paymentMethods: paymentFilter?.slug,
    region: countryFilter?.code,
    page,
  });

  const {
    data: businessTradingRequestsResponse,
    isFetching: isFetchingBusinessTrades,
    isError: isBusinessTradeError,
    isLoading: isLoadingBusinessTrades,
    refetch: refetchBusinessTrades,
  } = useB2bLiveTrades({
    businessId: businessId,
    tradeType: activeType,
    currencyCode: currencyFilter?.value || userData?.currency_code,
    paymentMethods: paymentFilter?.slug,
    region: countryFilter?.code,
    page,
  });

  const tradingRequests = tradingRequestsResponse?.results || [];
  const businessTradingRequests = businessTradingRequestsResponse?.results || [];

  const isFetchingData = isFetching || isFetchingBusinessTrades;
  const isLoadingData = isLoading || isLoadingBusinessTrades;
  const isErrorState = isError || isBusinessTradeError;

  useEffect(() => {
    if (businessId) {
      refetchBusinessTrades().then(() => {
        setTableData(businessTradingRequests);
      });
    } else {
      setTableData(tradingRequests);
    }
  }, [businessId, businessTradingRequests, tradingRequests, refetchBusinessTrades]);

  const columns = useMemo(
    () => [
      { accessorKey: isBusinessTrade ? "business_name" : "trading_name", header: "Advertiser" },
      { accessorKey: "conversion_rate_usd", header: "Rate" },
      {
        accessorKey: "amount",
        header: "Amount",
        cell: ({ row }: any) => (
          <span className="grid">
            <span>{`· AKL ${row.original.amount_in_akl}`}</span>
            <span>{`· ${row.original.active_currency} ${row.original.amount}`}</span>
          </span>
        ),
      },
      {
        accessorFn: (row: any) =>
          `${row.active_currency} ${row.min_amount} - ${row.active_currency} ${row.max_amount}`,
        header: "Order Limit",
      },
      {
        accessorKey: "payment_methods",
        header: "Payment options",
        cell: ({ getValue }: any) => (
          <span className="grid">
            {getValue().map((method: any) => (
              <span key={uuidv4()}>{method.payment_option.name}</span>
            ))}
          </span>
        ),
      },
      { accessorKey: "region", header: "Region" },
      {
        accessorKey: "trade_type",
        header: "Action",
        cell: ({ row }: any) => (
          <Button
            onClick={() => setFocusedRequest(row.original)}
            disabled={isLoadingData}
            className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
          >
            {row.original.trade_type === BUY ? "Sell" : "Buy"}
          </Button>
        ),
      },
    ],
    [isLoadingData],
  );

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  useEffect(() => {
    console.log("Trading Request has next:", tradingRequestsResponse?.next);
    if (isBusinessTrade) {
      refetchBusinessTrades();
    } else {
      refetch();
    }
  }, [activeType, countryFilter, currencyFilter, paymentFilter, page]);

  return (
    <div className="mt-[2em]">
      <TableContainer>
        
        <TableHeading>
          <div className="flex flex-col gap-3 w-full">
            <div className="flex md:justify-between md:items-center w-full flex-col md:flex-row gap-2 md:gap-0">
              <div className="flex md:space-x-2 justify-between md:justify-normal">
                <Button
                  onClick={() => setActiveType(SELL)}
                  // disabled={isLoading}
                  style={{ background: isBuyRequests ? "#fff5f0" : "#fff", color: "#B52C2C" }}
                  className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
                >
                  Buy
                </Button>
                <Button
                  onClick={() => setActiveType(BUY)}
                  // disabled={isLoading}
                  style={{ background: !isBuyRequests ? "#fff5f0" : "#fff", color: "#B52C2C" }}
                  className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
                >
                  Sell
                </Button>
              </div>
              
              <Button
                onClick={() => setShowCreateRequest(true)}
                style={{ background: "#fff5f0", color: "#B52C2C" }}
                className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
              >
                Create Buy/Sell Request
              </Button>
            </div>
            <div className="flex mx-2 lg:items-center lg:justify-between lg:mx-0">
              <div className="grid w-[200px] max-w-sm items-center gap-1.5 m-1rem mr-3">
                <ReactSelect
                  onChange={(option: any) => setPaymentFilter(option)}
                  options={paymentOptions}
                  isClearable={true}
                  placeholder="Payment"
                  isDisabled={isFetching}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.slug}
                  value={paymentFilter}
                />
              </div>
              <div className="grid w-[200px] max-w-sm items-center gap-1.5 m-1rem mr-3">
                <ReactSelect
                  options={currencies}
                  placeholder="Currency"
                  formatOptionLabel={(option) => `${option.value} - ${option.label}`}
                  isClearable
                  isSearchable
                  isDisabled={isFetching}
                  onChange={(option: any) => {
                    setCurrencyFilter(option);
                  }}
                  value={currencyFilter}
                />
              </div>

              <div className="grid w-[250px] max-w-sm items-center gap-1.5 m-1rem">
                <ReactSelect
                  onChange={(option: any) => setCountryFilter(option)}
                  options={countries}
                  isClearable={true}
                  placeholder="Region"
                  isDisabled={isFetching}
                  value={countryFilter}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.code}
                />
              </div>
            </div>
          </div>
        </TableHeading>
        
        <TableWrapper height="calc(100vh - 450px)">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {isFetchingData ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Loading...
                  </TableCell>
                </TableRow>
              ) : isErrorState ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Error fetching data
                  </TableCell>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    <button onClick={() => refetch()}>Refetch</button>
                  </TableCell>
                </TableRow>
              ) : tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Ooops, there are no items at the moment.
                  </TableCell>
                </TableRow>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} style={{ fontSize: "0.85rem", color: "#707070" }}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        {/* Pagination Controls */}
        <div className="flex justify-center mt-4 mb-4">
          <button
            onClick={handlePrevPage}
            disabled={page === 1 || !tradingRequestsResponse?.previous}
            className={`btn ${
              page === 1 || !tradingRequestsResponse?.previous
                ? "cursor-not-allowed opacity-50"
                : ""
            }`}
          >
            {"<<  Previous"}
          </button>
          <span className="mx-4">Page {page}</span>
          <button
            onClick={handleNextPage}
            disabled={!tradingRequestsResponse?.next}
            className={`btn ${
              !tradingRequestsResponse?.next ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {"Next >>"}
          </button>
        </div>
      </TableContainer>
      {isRequestInFocus && (
        <AddTradeOrders
          open={isRequestInFocus}
          handleClose={() => setFocusedRequest({})}
          request={focusedRequest}
          businessId={businessId}
        />
      )}
      {showCreateRequest && (
        <AddTradeRequest
          businessId={businessId}
          businessName={businessName}
          open={showCreateRequest}
          handleClose={() => setShowCreateRequest(false)}
          countries={countries}
          countriesIsLoading={countriesIsLoading}
        />
      )}
    </div>
  );
};

export default LiveTrades;
