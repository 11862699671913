import { useState, useEffect } from "react";
import logo from "../../assests/images/logo-dark-h.png";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  HomeIcon,
  MarketplaceIcon,
  HistoryIcon,
  WalletIcon,
  AccountIcon,
  BusinessIcon,
} from "../../assests/svgs/NavIcons";

import { getUser } from "../../services/index";
import { NAV_ITEMS } from "../../helpers/constants";
import { theme } from "../../theme";
import styled from "styled-components";
import { SideNavContainer, NavHeader, NavList, NavItem, IconWrapper } from "./Styles";

const HamburgerButton = styled.button`
  display: none;
  position: fixed;
  top: 0.75rem;
  right: 1rem;
  z-index: 1000;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;

  @media (max-width: 768px) {
    display: block;
  }

  div {
    width: 25px;
    height: 3px;
    background-color: ${theme.colors.primary};
    margin: 4px 0;
    transition: 0.4s;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: ${theme.colors.primary};
  font-size: 1.5rem;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1001;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: ${theme.colors.primary};
    transform-origin: center;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const MobileNavContainer = styled(SideNavContainer)<{ isOpen: boolean }>`
  @media (max-width: 768px) {
    transform: translateX(${props => props.isOpen ? '0' : '-100%'});
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    box-shadow: ${props => props.isOpen ? '2px 0 8px rgba(0,0,0,0.1)' : 'none'};
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  display: none;
  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
`;

const MobileLogo = styled.div`
  display: none;
  position: fixed;
  top: 0.75rem;
  left: 1rem;
  z-index: 1000;

  @media (max-width: 768px) {
    display: block;
  }

  img {
    height: 30px;
    width: auto;
  }
`;

const SideNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<NAV_ITEMS>();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleNavigation = (callback: () => void) => {
    callback();
    setIsMobileMenuOpen(false);
  };

  const links = [
    {
      Icon: HomeIcon,
      title: NAV_ITEMS.TRANSFER,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.TRANSFER);
        navigate("/home/transfer");
      },
    },
    {
      Icon: WalletIcon,
      title: NAV_ITEMS.WALLETS,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.WALLETS);
        navigate("/home/wallets");
      },
    },
    {
      Icon: HistoryIcon,
      title: NAV_ITEMS.HISTORY,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.HISTORY);
        navigate("/home/history");
      },
    },
    {
      Icon: MarketplaceIcon,
      title: NAV_ITEMS.MARKETPLACE,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.MARKETPLACE);
        navigate("/home/marketplace");
      },
    },
    {
      Icon: BusinessIcon,
      title: NAV_ITEMS.BUSINESS,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.BUSINESS);
        navigate("/home/business");
      },
    },
    {
      Icon: MarketplaceIcon,
      title: NAV_ITEMS.TRADING,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.TRADING);
        navigate("/home/trading");
      },
    },
    {
      Icon: AccountIcon,
      title: NAV_ITEMS.ACCOUNT,
      handleClick: () => {
        setSelectedItem(NAV_ITEMS.ACCOUNT);
        navigate("/home/account");
      },
    },
  ];

  useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  useEffect(() => {
    const pathMap = {
      "/transfer": NAV_ITEMS.TRANSFER,
      "/wallets": NAV_ITEMS.WALLETS,
      "/history": NAV_ITEMS.HISTORY,
      "/marketplace": NAV_ITEMS.MARKETPLACE,
      "/business": NAV_ITEMS.BUSINESS,
      "/trading": NAV_ITEMS.TRADING,
      "/account": NAV_ITEMS.ACCOUNT,
    };

    const selectedItem = Object.entries(pathMap).find(([path]) =>
      location?.pathname.includes(path),
    );

    setSelectedItem(selectedItem ? selectedItem[1] : NAV_ITEMS.TRANSFER);
  }, [location.pathname]);

  return (
    <>
      <MobileLogo>
        <img src={logo} alt="Hanypay Logo" />
      </MobileLogo>
      <HamburgerButton onClick={toggleMobileMenu} aria-label="Toggle navigation menu">
        <div />
        <div />
        <div />
      </HamburgerButton>
      <Overlay isOpen={isMobileMenuOpen} onClick={() => setIsMobileMenuOpen(false)} />
      <MobileNavContainer isOpen={isMobileMenuOpen}>
        {isMobileMenuOpen && <CloseButton onClick={toggleMobileMenu}>✕</CloseButton>}
        <NavHeader>
          <img src={logo} alt="Hanypay Logo" />
        </NavHeader>
        <NavList>
          {links.map(({ Icon, title, handleClick }) => (
            <NavItem
              key={title}
              onClick={() => handleNavigation(handleClick)}
              active={selectedItem === title}
            >
              <IconWrapper>
                <Icon 
                  fill={title === selectedItem ? theme.colors.secondary : theme.colors.text}
                  selected={Boolean(title === selectedItem)} 
                />
              </IconWrapper>
              {title}
            </NavItem>
          ))}
        </NavList>
      </MobileNavContainer>
    </>
  );
};

export default SideNavigation;
