import { useQuery } from "@tanstack/react-query";
import { SubmitHandler, FormProvider, useForm } from "react-hook-form";
import { FormItem, FormMessage } from "./common/ui/form";
import { Label } from "./common/ui/label";
import { useMutation } from "@tanstack/react-query";

import { getUser, updateUser } from "../services";
import useReactForm from "../hooks/useForm";
import { Button } from "./common/ui/button";
import { Input } from "./common/ui/input";
import { useToast } from "./common/ui/use-toast";
import {
  AccountDetailsTitle,
  AccountDetailsContext,
  AccountDetailsTitleDescription,
  AccountMainContainer,
  FlexColumn,
} from "./common/Styles";
import { FormContainer, TitleDescription } from "./Styles";
import validate from "../helpers/validate";
import { ExitIcon } from "@radix-ui/react-icons";
import { useAuth } from "src/hooks/useAuth";
import { useEffect, useState } from "react";
import { useGetUserPaymentMethods } from "src/hooks/useTrading";
import AddProfileMethods from "./common/AddPaymentMethods";
import { LucideEdit } from "lucide-react";

const USER_CARD = "USER_CARD";
const TRADE_PROFILE = "TRADE_PROFILE";

const Profile = () => {
  const { toast } = useToast();
  const methods = useForm();
  const { values, setValues, errors, handleChange } = useReactForm(validate);

  const [activeTab, setActiveTab] = useState(USER_CARD);
  const [showAddPaymentOption, setShowAddPaymentOption] = useState(false);
  const [focusedPayment, setFocusedPayment] = useState({});

  const showAddOrEditPaymentOption =
    showAddPaymentOption || Object.values(focusedPayment).length > 0;

  const { data: paymentMethodsResponse } = useGetUserPaymentMethods();
  const paymentMethods = paymentMethodsResponse?.results || [];

  const isUserDetails = activeTab === USER_CARD;
  const isTradeProfile = activeTab === TRADE_PROFILE;

  const disbaleSaveChanges =
    !values.firstname &&
    !values.lastname &&
    !values.phone &&
    !values.phone &&
    !values.country &&
    !values.city;

  const {
    isLoading,
    isError,
    data: userDataResponse,
    refetch,
  } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  const userData = userDataResponse || {};

  const createMutation = useMutation({
    mutationFn: (payload) => updateUser(userData?.id, payload),
    onSuccess: () => {
      toast({
        variant: "default",
        description: "Your details were updated successfully.",
      });
    },
    onError: (error: any) => {
      const message = error?.response?.data;
      if (message && typeof message === "object") {
        Object.keys(message).forEach((field) => {
          toast({
            variant: "destructive",
            title: `${field.charAt(0).toUpperCase() + field.slice(1)}`,
            description: message[field][0],
          });
        });
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "There was a problem with your request.",
        });
      }
    },
  });

  const userLoaded = userData && Object.values(userData).length > 0;

  useEffect(() => {
    setValues({
      firstname: userData?.firstname,
      lastname: userData?.lastname,
      email: userData?.email,
      phone: userData?.phone,
      country: userData?.country,
      address: userData?.address,
      city: userData?.city,
      trading_name: userData?.trading_name,
    });
  }, [userLoaded]);

  const handleSubmit: SubmitHandler<any> = (event) => {
    event.preventDefault();
    const payload = {
      ...values,
    };
    createMutation.mutate(payload);
  };

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    window.history.replaceState(null, "", "/");
  };

  return (
    <>
      <AccountMainContainer>
        <FlexColumn>
          <AccountDetailsContext onClick={() => setActiveTab(USER_CARD)} isActive={isUserDetails}>
            <AccountDetailsTitle>User Details</AccountDetailsTitle>
            <AccountDetailsTitleDescription>
              Update your personal details here.{" "}
              <button
                onClick={handleLogout}
                className="flex items-center justify-center px-6 text-sm leading-6 text-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
              >
                <ExitIcon className="mr-1" /> Logout
              </button>
            </AccountDetailsTitleDescription>
          </AccountDetailsContext>
          <AccountDetailsContext
            onClick={() => setActiveTab(TRADE_PROFILE)}
            isActive={isTradeProfile}
          >
            <AccountDetailsTitle>Trade profile</AccountDetailsTitle>
            <AccountDetailsTitleDescription>
              Update your trade profile here.{" "}
            </AccountDetailsTitleDescription>
          </AccountDetailsContext>
        </FlexColumn>
        {isUserDetails && (
          <FormContainer style={{ height: "80vh", overflowY: "auto", paddingRight: 32 }}>
            {isLoading && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                Loading...
              </div>
            )}
            {isError && (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                There was an error fetching user data.
                <Button
                  onClick={() => refetch()}
                  className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
                >
                  Retry
                </Button>
              </div>
            )}
            <FormProvider {...methods}>
              {userData && (
                <form className="flex flex-col w-full mb-[20rem] lg:mb-0">
                  <FormItem>
                    <Label>First name</Label>
                    <Input
                      name="firstname"
                      placeholder="eg: John Doe"
                      onChange={handleChange}
                      defaultValue={userData.firstname || ""}
                    />
                    {errors?.firstname && (
                      <FormMessage>{errors?.firstNameErrorMessage}</FormMessage>
                    )}
                  </FormItem>

                  <FormItem className="mt-5">
                    <Label>Last name</Label>
                    <Input
                      name="lastname"
                      placeholder="eg: John Doe"
                      onChange={handleChange}
                      defaultValue={userData.lastname || ""}
                    />
                    {errors?.lastname && <FormMessage>{errors?.lastNameErrorMessage}</FormMessage>}
                  </FormItem>

                  <FormItem className="mt-5">
                    <Label>Email</Label>
                    <Input name="email" disabled={true} defaultValue={userData.email || ""} />
                    {errors?.lastname && <FormMessage>{errors?.lastNameErrorMessage}</FormMessage>}
                  </FormItem>

                  <FormItem className="mt-5">
                    <Label>Phone number</Label>
                    <Input
                      type="tel"
                      name="phone"
                      disabled={true}
                      defaultValue={userData.phone || ""}
                    />
                    {errors?.phone && <FormMessage>{errors?.phoneErrorMessage}</FormMessage>}
                  </FormItem>

                  <FormItem className="mt-5">
                    <Label>Country</Label>
                    <Input
                      type="text"
                      name="country"
                      placeholder="Ghana"
                      disabled={true}
                      onChange={handleChange}
                      defaultValue={userData.country || ""}
                    />
                  </FormItem>

                  <FormItem className="mt-5">
                    <Label>Address</Label>
                    <Input
                      type="text"
                      name="address"
                      onChange={handleChange}
                      defaultValue={userData.address || ""}
                    />
                    {errors?.address && <FormMessage>{errors?.addressErrorMessage}</FormMessage>}
                  </FormItem>

                  <FormItem className="mt-5">
                    <Label>City</Label>
                    <Input
                      type="text"
                      name="city"
                      onChange={handleChange}
                      defaultValue={userData.city || ""}
                    />
                    {errors?.city && <FormMessage>{errors?.cityErrorMessage}</FormMessage>}
                  </FormItem>
                  <FormItem className="mt-5">
                    <Label>Trade name</Label>
                    <Input
                      type="text"
                      name="trading_name"
                      placeholder="Ghana"
                      onChange={handleChange}
                      defaultValue={userData.trading_name || ""}
                    />
                  </FormItem>

                  <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={createMutation.isPending || disbaleSaveChanges}
                      className="flex items-center justify-center py-2 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400"
                    >
                      {createMutation.isPending ? "loading..." : "Save changes"}
                    </Button>
                  </div>
                </form>
              )}
            </FormProvider>
          </FormContainer>
        )}
        {isTradeProfile && (
          <FlexColumn>
            <Button
              onClick={() => setShowAddPaymentOption(true)}
              style={{
                background: "#fff",
                color: "#262626",
                paddingLeft: 10,
                width: "fit-content",
              }}
              className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
            >
              Add payment option
            </Button>
            <FlexColumn>
              {paymentMethods?.map((option: any) => {
                const { id, account_details, payment_option } = option;
                return (
                  <div style={{ display: "flex", gap: 10 }} key={id}>
                    <div>
                      <TitleDescription>{payment_option?.name || "--"}</TitleDescription>
                      <span style={{ display: "flex", gap: 8 }}>
                        <span>&#8226;</span>
                        <AccountDetailsTitleDescription
                          style={{ whiteSpace: "nowrap" }}
                        >{`Account name: ${account_details?.name}`}</AccountDetailsTitleDescription>
                      </span>
                      <span style={{ display: "flex", gap: 8 }}>
                        <span>&#8226;</span>
                        <AccountDetailsTitleDescription
                          style={{ whiteSpace: "nowrap" }}
                        >{`Account number: ${account_details?.account_number}`}</AccountDetailsTitleDescription>
                      </span>
                    </div>
                    <Button
                      onClick={() => setFocusedPayment(option)}
                      disabled={isLoading}
                      style={{ background: "#fff5f0", color: "#B52C2C" }}
                      className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
                    >
                      <LucideEdit height={13} width={13} style={{ marginRight: 8 }} /> Edit
                    </Button>
                  </div>
                );
              })}
            </FlexColumn>
          </FlexColumn>
        )}
      </AccountMainContainer>
      {showAddOrEditPaymentOption && (
        <AddProfileMethods
          open={showAddOrEditPaymentOption}
          onClose={() => {
            setShowAddPaymentOption(false);
            setFocusedPayment({});
          }}
          focusedPayment={focusedPayment}
        />
      )}
    </>
  );
};

export default Profile;
