import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Wrapper } from "../Styles";
import { Button } from "./ui/button";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDelete from "./ConfirmDelete";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import {
  updateBusinessData,
  getUser,
  getCountries,
  getBusinesses,
  getSelectedBusiness,
  deleteBusiness,
  getBusinessTypes,
} from "../../services/index";
import useReactForm from "../../hooks/useForm";
import BusinesDetails from "../common/BusinessDetails";
import BusinessDocuments from "../common/BusinessDocuments";
import validate from "../../helpers/validate";
import { useToast } from "../common/ui/use-toast";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../common/ui/accordion";
import {
  AccountDetailsContext,
  AccountDetailsTitle,
  AccountDetailsTitleDescription,
  AccountMainContainer,
} from "./Styles";

function BusinessSelect() {
  const methods = useForm();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { id } = useParams();

  const { values, errors, setValues, handleChange } = useReactForm(validate);

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  const disbaleSaveChanges =
    !values.name &&
    !values.description &&
    !values.email &&
    !values.phone &&
    !values.country &&
    !values.id_director_one &&
    !values.id_director_two &&
    !values.address &&
    !values.size &&
    !values.transaction_volume;

  const { data: userData, refetch: refetchUserData } = useQuery({
    queryKey: ["userDetails"],
    queryFn: () => getUser(),
  });

  const { data: selectedBusinessData } = useQuery({
    queryKey: ["selectedBusiness"],
    queryFn: () => getSelectedBusiness(id),
    enabled: !!id,
  });

  const { data: businessTypes, isLoading: businessTypesIsLoading } = useQuery({
    queryKey: ["businessTypes"],
    queryFn: getBusinessTypes,
  });

  const { data: countries, isLoading: countriesIsLoading } = useQuery({
    queryKey: ["countries"],
    queryFn: () => getCountries(),
  });

  const { refetch: refetchBusinessData } = useQuery({
    queryKey: ["businessDetails", userData?.id],
    queryFn: () => getBusinesses(userData?.id),
    enabled: !!userData?.id,
  });

  const deleteBusinessMutation = useMutation({
    mutationKey: ["updateUser"],
    mutationFn: () => deleteBusiness(selectedBusinessData?.id),
    onSuccess: async () => {
      toast({
        description: "Business data has been deleted successfully.",
      });
      await refetchBusinessData();
      setShowConfirmDelete(false);
      navigate(-1);
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete business data.",
      });
    },
  });

  const updateBusinessDataMutation = useMutation({
    mutationKey: ["updateUser"],
    mutationFn: (payload: object) => updateBusinessData(id, payload),
    onSuccess: async () => {
      toast({
        description: "Business data has been updated successfully.",
      });
      await refetchUserData();
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update business data.",
      });
    },
  });

  const handleBusinessDataSubmit = () => {
    updateBusinessDataMutation.mutate(values);
  };

  const handleCountrySelect = (event: any) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      country: event,
    }));
  };

  const handleConfirmDelete = () => {
    deleteBusinessMutation.mutate();
  };

  const handleBusinessTypeSelect = (event: any) => {
    setValues((prevValues: any) => ({
      ...prevValues,
      type: event,
    }));
  };

  return (
    <div
      className="w-[95%] lg:w-[78%] mt-[3rem] h-[100vh] overflow-y-scroll ml-auto pr-[6vw]"
    >
      <Button onClick={() => navigate(-1)} variant="outline">
        <ArrowLeftIcon style={{ marginRight: "0.5rem" }} /> Go back
      </Button>

      <div style={{ margin: "2rem 0 1rem" }}>
        <AccountDetailsTitle style={{ fontSize: "1.5rem" }}>
          {selectedBusinessData?.name}
        </AccountDetailsTitle>
        <AccountDetailsTitleDescription>
          {selectedBusinessData?.description}
        </AccountDetailsTitleDescription>
      </div>

      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>Business Details</AccordionTrigger>
          <AccordionContent>
            <BusinesDetails
              methods={methods}
              handleChange={handleChange}
              userData={userData}
              errors={errors}
              countriesIsLoading={countriesIsLoading}
              handleCountrySelect={handleCountrySelect}
              countries={countries}
              handleBusinessDataSubmit={handleBusinessDataSubmit}
              updateBusinessDataMutation={updateBusinessDataMutation}
              businessData={selectedBusinessData}
              disbaleSaveChanges={disbaleSaveChanges}
              businessTypesIsLoading={businessTypesIsLoading}
              businessTypes={businessTypes}
              handleBusinessTypeSelect={handleBusinessTypeSelect}
            />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-2">
          <AccordionTrigger>Business Documents</AccordionTrigger>
          <AccordionContent>
            <BusinessDocuments id={id} />
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-3">
          <AccordionTrigger>Advanced</AccordionTrigger>
          <AccordionContent>
            <AccountMainContainer>
              <AccountDetailsContext>
                <AccountDetailsTitle> Delete Business</AccountDetailsTitle>{" "}
                <AccountDetailsTitleDescription>
                  Note that, by clicking on delete, this business account will be deleted and all
                  data associated with it.
                </AccountDetailsTitleDescription>
              </AccountDetailsContext>
              <Button
                onClick={() => setShowConfirmDelete(true)}
                variant="secondary"
                disabled
                className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
              >
                Delete Business
              </Button>
            </AccountMainContainer>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <ConfirmDelete
        open={showConfirmDelete}
        isLoading={deleteBusinessMutation.isPending}
        handleConfirmDelete={handleConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
      />
    </div>
  );
}

export default BusinessSelect;
