import React, { useEffect, useState } from "react";
import TradeOrders from "./TradeOrders";
import UserTradeOrders from "./UserTradeOrders";
import { Button } from "./ui/button";
import LiveTrades from "./LiveTrades";
import { useUserData } from "src/hooks/useUserData";
import { getBusinesses } from "src/services";
import { toast } from "./ui/use-toast";
import { useQuery } from "@tanstack/react-query";
import ReactSelect from "react-select";
import BusinessTradeOrders from "./BusinessTradeOrders";

interface TabItem {
  name: string;
}

const SUB_NAV_ITEMS: TabItem[] = [{ name: "Live Trade" }, { name: "Orders" }, { name: "My Trade" }];

interface RenderTabs {
  [key: string]: {
    component: JSX.Element;
  };
}

type Business = {
  id: string;
  name: string;
  address: string;
  phone: string;
  email: string;
  type: string;
  size: string;
  description: string;
  hanypay_id: string;
  swifin_id: string;
  transaction_volume: string;
  id_director_one: string;
  id_director_two: string;
  country: string;
};

const BusinessTrading = () => {
  const { userData } = useUserData();

  const {
    data: businessData,
    refetch: refetchBusinessData,
    isLoading: businessesIsLoading,
    isError: errorLoadingIsBusiness,
    isSuccess: businessDataSuccess,
  } = useQuery({
    queryKey: ["businessDetails", userData?.id],
    queryFn: () => handleGetBusinessData(userData?.id),
    enabled: !!userData?.id,
  });

  const [selectedSubNav, setSelectedSubNav] = useState<string>(SUB_NAV_ITEMS[0].name);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);

  useEffect(() => {
    if (businessDataSuccess) setSelectedBusiness(businessData[0]);
  }, [businessDataSuccess]);

  const businessId = selectedBusiness?.id;

  const renderNavs: RenderTabs = {
    "Live Trade": {
      component: <LiveTrades businessId={businessId} businessName={selectedBusiness?.name} />,
    },
    Orders: { component: <BusinessTradeOrders businessId={businessId} /> },
    "My Trade": {
      component: <UserTradeOrders businessId={businessId} businessName={selectedBusiness?.name} />,
    },
  };

  const handleGetBusinessData = async (userId: string) => {
    try {
      return await getBusinesses(userId);
    } catch (error: any) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to load business.",
      });
    }
  };

  const handleBusinessSelect = (event: any) => {
    setSelectedBusiness(event);
  };

  return (
    <>
      <div
        className="mt-[2em] flex md:justify-between md:flex-row md:items-center w-[95%] flex-col gap-2 md:gap-0" 
      >
    
          {/* Sub-navigation for P2P Trade */}
          <div>
            {SUB_NAV_ITEMS.map(({ name }) => (
              <Button
                key={name}
                onClick={() => setSelectedSubNav(name)}
                style={{
                  background: selectedSubNav === name ? "#fff5f0" : "#fff",
                  color: selectedSubNav === name ? "#B52C2C" : "#000",
                }}
                className="sub-nav-button"
              >
                {name}
              </Button>
            ))}
          </div>
    
        <div className="w-full md:w-4/12 md:mr-2">
          <ReactSelect
            onChange={(option: any) => setSelectedBusiness(option)}
            options={businessData}
            placeholder="Select business"
            isDisabled={businessesIsLoading}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            value={selectedBusiness}
          />
          {/* <Select
            // value={selectedBusiness?.id}
            disabled={businessesIsLoading}
            onValueChange={(event) => handleBusinessSelect(event)}
          >
            <SelectTrigger
              className="w-full"
              onChange={(event: any) => handleBusinessSelect(event)}
            >
              <SelectValue placeholder="Select business" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {businessData?.map(({ id, name }: { id: string; name: string }) => (
                  <SelectItem key={id} value={id}>
                    {name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select> */}
        </div>
      </div>
      {Boolean(businessId) && renderNavs[selectedSubNav].component}
    </>
  );
};

export default BusinessTrading;
