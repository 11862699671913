import { ChangeEvent, useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { FormProvider, useForm } from "react-hook-form";
import { FormItem } from "./ui/form";
import { TitleDescription, WalletContainer } from "../Styles";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { useCreatePaymentMethodsMutationApi, useGetPaymentOptions } from "src/hooks/useTrading";
import { useUserData } from "src/hooks/useUserData";
import { Button } from "./ui/button";

const AddProfileMethods = ({
  open,
  onClose,
  focusedPayment,
}: {
  open: boolean;
  onClose: () => void;
  focusedPayment: any;
}) => {
  const [paymentOption, setPaymentOption] = useState<any>({});
  const [paymentNote, setPaymentNote] = useState("");
  const [accountDetails, setAccountDetails] = useState({
    name: "",
    account_number: "",
    provider_name: "",
  });

  const { id: focusedPaymentId, account_details, payment_option, note } = focusedPayment;

  const isEditState = Object.values(focusedPayment).length > 0;

  useEffect(() => {
    if (isEditState) {
      setPaymentOption(payment_option.id);
      setPaymentNote(note);
      setAccountDetails({
        account_number: account_details?.account_number,
        provider_name: account_details?.provider_name,
        name: account_details?.name,
      });
    }
  }, [isEditState]);

  const methods = useForm();

  const { userData } = useUserData();

  const { createPaymentMethod, editPaymentMethod } = useCreatePaymentMethodsMutationApi();

  const { data: paymentOptions, isLoading: isLoadingPaymentOptions } = useGetPaymentOptions();

  const handlePaymentOptionSelect = (event: any) => {
    setPaymentOption(event);
  };

  function handleCreatePaymentMethods() {
    const payload = {
      user_id: userData?.id,
      payment_option: paymentOption,
      account_details: accountDetails,
      note: paymentNote,
    };
    isEditState
      ? editPaymentMethod.mutate({ id: focusedPaymentId, payload: payload })
      : createPaymentMethod.mutate(payload);
  }

  useEffect(() => {
    if (createPaymentMethod.isSuccess || editPaymentMethod.isSuccess) {
      onClose();
    }
  }, [createPaymentMethod.isSuccess, editPaymentMethod.isSuccess]);

  const disableCreateProfile =
    paymentOption.length < 1 ||
    accountDetails.account_number === "" ||
    accountDetails.name === "" ||
    accountDetails.provider_name === "";

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay style={{ inset: 1000 }} className="DialogOverlay">
          <Dialog.Content className="DialogContent">
            <Dialog.Title style={{ marginBottom: 24 }} className="DialogTitle">
              Add payment methods
            </Dialog.Title>
            <Dialog.Close asChild>
              <button className="IconButton" aria-label="Close" onClick={onClose}>
                <Cross2Icon />
              </button>
            </Dialog.Close>
            <FormProvider {...methods}>
              <FormItem className="mt-3">
                <TitleDescription>Select payment option</TitleDescription>
                <Select
                  disabled={isLoadingPaymentOptions}
                  value={paymentOption}
                  onValueChange={(event) => handlePaymentOptionSelect(event)}
                >
                  <SelectTrigger
                    className="w-full"
                    onChange={(event: any) => handlePaymentOptionSelect(event)}
                  >
                    <SelectValue placeholder="select option" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {paymentOptions?.map(({ name, id }: { name: any; id: string }) => (
                        <SelectItem key={id} value={id}>
                          {name}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormItem>
              <div>
                <TitleDescription style={{ marginTop: 15 }}>Account details</TitleDescription>
                <WalletContainer>
                  <FormItem>
                    <TitleDescription>Payment Provider name</TitleDescription>
                    <Input
                      type="text"
                      id="providerName"
                      placeholder="Bank name, Mobile Money Provider name "
                      onChange={(event) => {
                        setAccountDetails((prev: any) => ({
                          ...prev,
                          provider_name: event.target.value,
                        }));
                      }}
                      defaultValue={accountDetails.provider_name}
                    />
                  </FormItem>

                  <FormItem style={{ marginTop: 15 }}>
                    <TitleDescription>Account number</TitleDescription>
                    <Input
                      type="text"
                      id="accountNumber"
                      placeholder="eg. 00000000000"
                      onChange={(event) => {
                        setAccountDetails((prev: any) => ({
                          ...prev,
                          account_number: event.target.value,
                        }));
                      }}
                      defaultValue={accountDetails.account_number}
                    />
                  </FormItem>
                  <FormItem style={{ marginTop: 15 }}>
                    <TitleDescription>Account name</TitleDescription>
                    <Input
                      type="text"
                      id="accountName"
                      placeholder="John Doe"
                      onChange={(event) => {
                        setAccountDetails((prev: any) => ({
                          ...prev,
                          name: event.target.value,
                        }));
                      }}
                      defaultValue={accountDetails.name}
                    />
                  </FormItem>
                  <FormItem style={{ marginTop: 15 }}>
                    <TitleDescription>Note</TitleDescription>
                    <textarea
                      id="paymentNote"
                      name="paymentNote"
                      rows={5}
                      cols={50}
                      placeholder="Add note"
                      value={paymentNote}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        setPaymentNote(e.target.value)
                      }
                      className={
                        "appearance-none flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                      }
                    ></textarea>
                  </FormItem>
                </WalletContainer>
              </div>
            </FormProvider>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginTop: 20,
              }}
            >
              <Button
                onClick={handleCreatePaymentMethods}
                disabled={disableCreateProfile}
                className="flex items-center justify-center py-1 rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mt-2"
              >
                Save
              </Button>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default AddProfileMethods;
